import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { persist } from "zustand/middleware";
import { Api } from "../apis/api";
import { getCurrentWeekNumber } from "../utils/datetime";
import { IWorkout } from "../types/workoutplan";

const STORAGE_KEY = "workout-plan";

export interface IWorkoutPlanState {
  currentWeek: number | null;
  workouts: Record<string, IWorkout>;
  allWorkouts: IWorkout[];
  lastFetched: string | null;
  loved: Record<string, boolean>;
  done: Record<string, boolean>;
  setCurrentWeek: () => void;
  fetchWorkoutPlan: (workoutPlanId: string, gender: string) => Promise<void>;
  toggleDone: (workoutId: string) => void;
  toggleLove: (workoutId: string) => void;
  getLikedWorkouts: (workoutToChangeId: string) => IWorkout[];
}

export const useWorkoutPlan = create<IWorkoutPlanState>()(
  immer(
    persist(
      (set, get) => ({
        currentWeek: null,
        workouts: {},
        allWorkouts: [],
        lastFetched: null,
        loved: {},
        done: {},
        setCurrentWeek() {
          const currentStateWeek = get().currentWeek;
          const currentWeek = getCurrentWeekNumber();

          if (currentStateWeek !== currentWeek) {
            set((s) => {
              s.currentWeek = currentWeek;
              s.done = {};
            });
          }
        },
        resetWorkoutPlan() {
          localStorage.removeItem(STORAGE_KEY);
          set((s) => {
            s.workouts = {};
            s.lastFetched = null;
          });
        },
        async fetchWorkoutPlan(workoutPlanId, gender) {
          const [workoutPlan, workouts] = await Promise.all([
            Api.getWorkoutPlan(workoutPlanId),
            Api.getPlanWorkouts(workoutPlanId, gender),
          ]);
          set((s) => {
            s.workouts = workouts;
            s.allWorkouts = workoutPlan[gender];
            s.lastFetched = new Date().toISOString();
          });
        },
        toggleDone(workoutId) {
          set((s) => {
            s.done[workoutId] = !s.done[workoutId];
          });
        },
        toggleLove(workoutId) {
          set((s) => {
            s.loved[workoutId] = !s.loved[workoutId];
          });
        },
        getLikedWorkouts(workoutToChangeId) {
          const { workouts, loved } = get();
          return (
            Object.values(workouts).filter(
              (m) => loved[m.workoutId] && m.workoutId !== workoutToChangeId
            ) || []
          );
        },
      }),
      {
        name: STORAGE_KEY,
      }
    )
  )
);
