import { FC } from "react";
import styled from "styled-components";
import { WorkoutCard } from "../cards/WorkoutCard";

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ItemTitle = styled.h2`
  margin: 0;
  font-size: 18px;
  margin-bottom: 8px;
`;

interface Props {
  title: string;
  id: string;
  goalCard?: boolean;
}

export const WorkoutCarouselItem: FC<Props> = ({
  title,
  id,
  goalCard = false,
}) => {
  return (
    <Container>
      {!goalCard && <ItemTitle>{title}</ItemTitle>}
      <WorkoutCard workoutId={id} goalCard={goalCard} />
    </Container>
  );
};
