import axios, { AxiosError, isAxiosError } from "axios";
import { IMeal } from "../types/meal";
import { IMealPlan } from "../types/mealplan";
import { IShoppingList } from "../types/shoppingList";
import { IProfile } from "../types/profile";
import { throttle } from "lodash";
import { configValues } from "../config-values";
import { IWorkout } from "../types/workoutplan";
import { workoutPlanResponse } from "./workoutPlanResponse";

const instance = axios.create({
  baseURL: configValues.apiUrl,
});

export class Api {
  static getLastReportedMessage() {
    // Retrieve the last reported message from session storage
    return sessionStorage.getItem("lastReportedMessage") || "";
  }

  static setLastReportedMessage(message) {
    // Save the last reported message to session storage
    sessionStorage.setItem("lastReportedMessage", message);
  }

  static reportError = throttle(
    async (
      error: Error | AxiosError | unknown,
      context: Record<string, unknown>
    ) => {
      console.log(error);
      let message = (error as any)?.message || "Unknown error";
      let stack = (error as any)?.stack;
      let axiosError = null;

      if (message === Api.getLastReportedMessage()) {
        return;
      }
      Api.setLastReportedMessage(message);

      if (isAxiosError(error)) {
        axiosError = error.toJSON();
      }

      instance.post("report-error", {
        message,
        stack,
        context,
        axiosError,
        url: window.location.href,
      });
    },
    1000
  );

  static login = async (email: string, password: string) => {
    const res = await instance.post(`auth`, {
      email,
      password,
    });
    return res.data as IProfile;
  };

  static user = async (id: string) => {
    const res = await instance.get(`user?id=${id}`);
    return res.data as IProfile;
  };

  static update = async (profile: Partial<IProfile>) => {
    const res = await instance.post(`update-user`, profile);
    return res.data as Partial<IProfile>;
  };

  static deleteUser = async (id: string) => {
    const res = await instance.get(`delete-user?id=${id}`);
    return res.data;
  };

  static requestPasswordReset = async (email: string) => {
    const res = await instance.post(`reset-password`, { email });
    return res.data;
  };

  static resetPassword = async (email: string) => {
    try {
      const res = await axios.post(
        `${configValues.websiteUrl}api/api/v1/auth/forgotPassword`,
        {
          email,
        }
      );
      return res.data;
    } catch (e) {
      console.log(e);
    }
  };

  static generateMealPlan = async (
    uid: string,
    quizData: Record<string, string | string[]>
  ) => {
    const res = await instance.post(`generate-meal-plan`, {
      uid,
      quizData,
    });
    return res.data as IMealPlan;
  };

  static getMealPlan = async (mealPlanId: string) => {
    const res = await instance.get(`get-meal-plan?mealPlanId=${mealPlanId}`);
    return res.data as IMealPlan;
  };

  static updateMealPlan = async (mealPlan: IMealPlan) => {
    const res = await instance.post(`update-meal-plan`, {
      mealPlan,
    });
    return res.data as IMealPlan;
  };

  static getPlanMeals = async (mealPlanId: string) => {
    const res = await instance.get(`get-plan-meals?mealPlanId=${mealPlanId}`);
    return res.data as Record<string, IMeal>;
  };

  static generateShoppingList = async (uid: string, ingredients: string[]) => {
    const res = await instance.post(`generate-shopping-list`, {
      uid,
      ingredients,
    });
    return res.data as IShoppingList;
  };

  static getShoppingList = async (listId: string) => {
    const res = await instance.get(`get-shopping-list?listId=${listId}`);
    return res.data as IShoppingList;
  };

  static getWorkoutPlan = async (_workoutPlanId: string) => {
    return workoutPlanResponse;
  };

  static getPlanWorkouts = async (_workoutPlanId: string, gender: string) => {
    const result: Record<string, IWorkout> = {};
    for (const workout of workoutPlanResponse[gender]) {
      result[workout.workoutId] = workout;
    }
    return result;
  };
}
