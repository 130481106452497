import { configValues } from "../config-values";

export const parseOutKg = (str: string) => {
  const kgPart = str.split("-")[1];
  const kgValue = kgPart.trim().split(" ")[0];
  return Number(kgValue);
};

export const convertBackToStr = (kg: number) => {
  const pounds = kg * 2.20462; // Convert kg to pounds
  return `${Math.floor(pounds)} lbs - ${Math.floor(kg)} kg`;
};

export const getWeightGoalText = (
  inital: number,
  current: number,
  goal: number,
  units: "metric" | "imperial"
) => {
  const kgOrLbs = units === "metric" ? "kg" : "lbs";
  const initialMinusCurrent = Math.floor(inital - current);
  const currentMinusGoal = Math.floor(current - goal);
  const currentMinusInitial = Math.floor(current - inital);

  if (initialMinusCurrent === 0) {
    return `Welcome to ${configValues.project.marketedTitle} app 👋 We're here to help you live healthier and reach your goals! Checkout your meal plan and set your water intake goal. Dont forget to track your weight daily and take your exercises.`;
  } else if (initialMinusCurrent > 0 && currentMinusGoal >= 0) {
    return `👏 Congrats! You have already lost ${initialMinusCurrent} ${kgOrLbs}. Keep going 💪`;
  } else if (initialMinusCurrent <= 0) {
    return `You have gained ${currentMinusInitial} ${kgOrLbs}. Dont panic! Each body reacts differently to new diets better health is on the horizon`;
  }
  return `You have reached your goal! 🙌🏅🏁`;
};
