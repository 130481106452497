import { FC } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import doneJson from "../../assets/lottie/done.json";
import Lottie from "react-lottie-player";
import { HeartSvg } from "../../assets/svg/HeartSvg";
import { HeartFullSvg } from "../../assets/svg/HeartFullSvg";
import { useWorkoutPlan } from "../../state/workoutPlan";

const Container = styled.div`
  border-radius: 0.5rem;
  overflow: hidden;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin-bottom: 20px;
`;

const ContainerGoalCard = styled.div`
  overflow: hidden;
  position: relative;
  background-color: #fff;
  margin: -1rem;
`;

const CardImage = styled.img`
  width: 100%;
  height: 160px;
  object-fit: cover;
`;

const CardContent = styled.div`
  padding-top: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
`;

const CardImageContent = styled.div`
  position: relative;
`;

const DoneContainer = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  overflow: hidden;
  position: absolute;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
`;

const CardTitle = styled.h2`
  font-size: 18px;
  margin: 0;
  margin-bottom: 8px;
`;

const CardDetails = styled.p`
  font-size: 14px;
  color: #666;
  margin: 0;
  margin-bottom: 16px;
`;

const ActionButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  color: #555;
  border: none;
  padding: 1rem;
  width: 100%;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
`;

const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
`;

const FavButton = styled.button`
  top: 0.5rem;
  right: 0.5rem;
  z-index: 10;
  width: 2rem;
  height: 2rem;
  padding-top: 2px;
  position: absolute;
  border-radius: 50%;
  background: white;
  display: flex;
  border: none;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

interface Props {
  workoutId: string;
  goalCard?: boolean;
}

export const WorkoutCard: FC<Props> = ({ workoutId, goalCard = false }) => {
  const { workouts, loved, done, toggleLove } = useWorkoutPlan();

  if (!workoutId) {
    return null;
  }
  const workout = workouts[workoutId as any];
  const markedDone = done[workout.workoutId];
  const isLikedMeal = loved[workout.workoutId];

  const Content = (
    <>
      {" "}
      <FavButton onClick={() => toggleLove(workout.workoutId)}>
        {isLikedMeal ? <HeartFullSvg /> : <HeartSvg />}
      </FavButton>
      <CardImageContent>
        <CardImage
          src={`/images/workouts/${workout.image}`}
          alt={workout.title}
        />
        {markedDone && (
          <DoneContainer>
            <Lottie
              play={true}
              loop={false}
              style={{ width: "100%" }}
              animationData={doneJson}
            />
          </DoneContainer>
        )}
      </CardImageContent>
      <CardContent>
        <CardTitle>{workout.title}</CardTitle>
        <CardDetails>
          {workout.macros?.duration} min · {workout.macros?.calories} kcal ·{" "}
          {workout.macros?.numExercises} exercises
        </CardDetails>
        <ActionsContainer>
          <ActionButton to={`/workouts/${workout.workoutId}`}>
            VIEW
          </ActionButton>
        </ActionsContainer>
      </CardContent>
    </>
  );

  return (
    <>
      {!goalCard && <Container>{Content}</Container>}
      {goalCard && <ContainerGoalCard>{Content}</ContainerGoalCard>}
    </>
  );
};
