import styled from "styled-components";
import { IExercise } from "../../types/workoutplan";

const WorkoutExerciseContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: start;
`;

const WorkoutExerciseImage = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 8px;
`;

const WorkoutInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: start;
`;

const WorkoutTitle = styled.p`
  font-weight: 500;
  font-size: 16px;
  padding: 0px;
  margin: 0px;
`;

const WorkoutPerformanceInfo = styled.p`
  color: #666666;
  font-size: 14px;
  padding: 0px;
  margin: 0px;
`;

type TProps = {
  exercise: IExercise;
  onExerciseTriggered: () => void;
};

export const WorkoutExerciseItem = ({
  exercise,
  onExerciseTriggered,
}: TProps) => {
  return (
    <WorkoutExerciseContainer onClick={onExerciseTriggered}>
      {exercise.image && <WorkoutExerciseImage src={exercise.image} />}
      <WorkoutInfoContainer>
        <WorkoutTitle>{exercise.title}</WorkoutTitle>
        <WorkoutPerformanceInfo>
          {exercise.duration && <>{exercise.duration} min</>}
          {exercise.duration && exercise.sets && exercise.reps && <> · </>}
          {exercise.sets && exercise.reps && (
            <>
              {exercise.sets} sets X {exercise.reps} reps
            </>
          )}
        </WorkoutPerformanceInfo>
      </WorkoutInfoContainer>
    </WorkoutExerciseContainer>
  );
};
