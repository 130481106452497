import { IWorkout } from "../types/workoutplan";

export const workoutPlanResponse: Record<string, IWorkout[]> = {
  female: [
    {
      workoutId: "0",
      title: "Lower Body Toner",
      image: "lower-body-toner.jpg",
      macros: {
        duration: 15,
        calories: 120,
        numExercises: 3,
      },
      exercises: [
        {
          id: "0",
          title: "Bodyweight Squats",
          duration: 5,
          sets: 3,
          reps: 15,
          instructions: [
            "Stand with feet shoulder-width apart.",
            "Lower your body as if sitting back into a chair, keeping your chest up.",
            "Push through your heels to return to the standing position.",
          ],
        },
        {
          id: "1",
          title: "Glute Bridges",
          duration: 5,
          sets: 3,
          reps: 20,
          instructions: [
            "Lie on your back with knees bent and feet flat on the floor.",
            "Push through your heels to lift your hips off the ground until your body forms a straight line.",
            "Lower your hips back down to the starting position.",
          ],
        },
        {
          id: "2",
          title: "Lunges",
          duration: 5,
          sets: 3,
          reps: 12,
          instructions: [
            "Stand tall and take a step forward with one leg.",
            "Lower your body until both knees are bent at a 90-degree angle.",
            "Push back to the starting position and repeat with the other leg.",
          ],
        },
      ],
    },
    {
      workoutId: "1",
      title: "Upper Body Strength",
      image: "upper-body-strength.jpg",
      macros: {
        duration: 15,
        calories: 110,
        numExercises: 3,
      },
      exercises: [
        {
          id: "3",
          title: "Push-Ups",
          duration: 5,
          sets: 3,
          reps: 15,
          instructions: [
            "With your legs extended back, place the hands below the shoulders, slightly wider than shoulder-width apart.",
            "Start bending your elbows and lower your chest until it's just above the floor.",
            "Push back to the starting position.",
          ],
        },
        {
          id: "4",
          title: "Tricep Dips",
          duration: 5,
          sets: 3,
          reps: 12,
          instructions: [
            "Sit on the edge of a chair, hands gripping the edge next to your hips.",
            "Move your hips off the chair and lower your body until your elbows form a 90-degree angle.",
            "Push back up to straighten your arms.",
          ],
        },
        {
          id: "5",
          title: "Bicep Curls with Resistance Bands",
          duration: 5,
          sets: 3,
          reps: 15,
          instructions: [
            "Stand on the resistance band with feet shoulder-width apart.",
            "Hold the handles with palms facing up.",
            "Curl the handles toward your shoulders, keeping your elbows close to your sides.",
          ],
        },
      ],
    },
    {
      workoutId: "2",
      title: "Core Crusher",
      image: "core-crusher.jpg",
      macros: {
        duration: 12,
        calories: 90,
        numExercises: 3,
      },
      exercises: [
        {
          id: "6",
          title: "Plank",
          duration: 3,
          sets: 3,
          reps: 1,
          instructions: [
            "Start in a push-up position but with your forearms on the ground.",
            "Keep your body in a straight line from head to heels.",
            "Hold this position without letting your hips sag for 30 seconds.",
          ],
        },
        {
          id: "7",
          title: "Russian Twists",
          duration: 4,
          sets: 3,
          reps: 20,
          instructions: [
            "Sit on the floor with your knees bent and feet lifted.",
            "Twist your torso to the right, bringing your hands or a weight to the side.",
            "Repeat on the left side.",
          ],
        },
        {
          id: "8",
          title: "Leg Raises",
          duration: 5,
          sets: 3,
          reps: 15,
          instructions: [
            "Lie on your back with legs extended.",
            "Raise your legs to a 90-degree angle, keeping them straight.",
            "Lower your legs slowly without letting them touch the floor.",
          ],
        },
      ],
    },
    {
      workoutId: "3",
      title: "Full Body Express",
      image: "full-body-express.jpg",
      macros: {
        duration: 18,
        calories: 150,
        numExercises: 4,
      },
      exercises: [
        {
          id: "9",
          title: "Jumping Jacks",
          duration: 3,
          sets: 2,
          reps: 25,
          instructions: [
            "Stand with feet together and arms at your sides.",
            "Jump your feet out to the sides while bringing your arms overhead.",
            "Jump back to the starting position.",
          ],
        },
        {
          id: "10",
          title: "Mountain Climbers",
          duration: 3,
          sets: 3,
          reps: 20,
          instructions: [
            "Start in a high plank position.",
            "Quickly drive one knee toward your chest, then switch legs.",
            "Continue alternating legs as fast as you can.",
          ],
        },
        {
          id: "11",
          title: "Bodyweight Squats",
          duration: 5,
          sets: 3,
          reps: 15,
          instructions: [
            "Stand with feet shoulder-width apart.",
            "Lower your body as if sitting back into a chair, keeping your chest up.",
            "Push through your heels to return to the standing position.",
          ],
        },
        {
          id: "12",
          title: "Push-Ups",
          duration: 5,
          sets: 3,
          reps: 10,
          instructions: [
            "With your legs extended back, place the hands below the shoulders, slightly wider than shoulder-width apart.",
            "Start bending your elbows and lower your chest until it's just above the floor.",
            "Push back to the starting position.",
          ],
        },
      ],
    },
    {
      workoutId: "4",
      title: "Morning Mobility",
      image: "morning-mobility.jpg",
      macros: {
        duration: 10,
        calories: 60,
        numExercises: 2,
      },
      exercises: [
        {
          id: "13",
          title: "Cat-Cow Stretch",
          duration: 5,
          sets: 3,
          reps: 10,
          instructions: [
            "Start on all fours with hands under shoulders and knees under hips.",
            "Arch your back (Cow) and lift your head and tailbone upward.",
            "Round your back (Cat) while tucking your chin and pelvis.",
          ],
        },
        {
          id: "14",
          title: "Downward Dog to Cobra",
          duration: 5,
          sets: 3,
          reps: 8,
          instructions: [
            "Start in a downward dog position with your hips lifted and heels pressed toward the floor.",
            "Shift forward into a plank position and lower into Cobra, arching your back and lifting your chest.",
            "Push back to downward dog.",
          ],
        },
      ],
    },
    {
      workoutId: "5",
      title: "Cardio Blast",
      image: "cardio-blast.jpg",
      macros: {
        duration: 15,
        calories: 130,
        numExercises: 3,
      },
      exercises: [
        {
          id: "15",
          title: "High Knees",
          duration: 5,
          sets: 3,
          reps: 30,
          instructions: [
            "Stand in place and run while lifting your knees as high as possible.",
            "Keep your core tight and move as quickly as possible.",
          ],
        },
        {
          id: "16",
          title: "Jumping Jacks",
          duration: 5,
          sets: 3,
          reps: 25,
          instructions: [
            "Stand with feet together and arms at your sides.",
            "Jump your feet out to the sides while bringing your arms overhead.",
            "Jump back to the starting position.",
          ],
        },
        {
          id: "17",
          title: "Butt Kicks",
          duration: 5,
          sets: 3,
          reps: 30,
          instructions: [
            "Stand in place and kick your heels toward your glutes while jogging in place.",
            "Move quickly to keep your heart rate up.",
          ],
        },
      ],
    },
    {
      workoutId: "6",
      title: "Back and Shoulders Builder",
      image: "back-and-shoulders-builder.jpg",
      macros: {
        duration: 15,
        calories: 110,
        numExercises: 3,
      },
      exercises: [
        {
          id: "18",
          title: "Resistance Band Rows",
          duration: 5,
          sets: 3,
          reps: 15,
          instructions: [
            "Sit on the floor with your legs extended and the resistance band looped around your feet.",
            "Hold the ends of the band with both hands and pull them toward your waist, squeezing your shoulder blades together.",
            "Slowly return to the starting position.",
          ],
        },
        {
          id: "19",
          title: "Lateral Raises with Dumbbells or Bands",
          duration: 5,
          sets: 3,
          reps: 12,
          instructions: [
            "Stand with feet shoulder-width apart, holding dumbbells or bands at your sides.",
            "Raise your arms out to the sides until they are parallel to the ground.",
            "Lower the weights back down with control.",
          ],
        },
        {
          id: "20",
          title: "Superman",
          duration: 5,
          sets: 3,
          reps: 15,
          instructions: [
            "Lie face down with arms extended overhead.",
            "Lift your arms, chest, and legs off the ground simultaneously, squeezing your glutes and lower back.",
            "Hold for 1-2 seconds before lowering back down.",
          ],
        },
      ],
    },
    {
      workoutId: "7",
      title: "Stretch and Flex",
      image: "stretch-and-flex.jpg",
      macros: {
        duration: 12,
        calories: 50,
        numExercises: 3,
      },
      exercises: [
        {
          id: "21",
          title: "Standing Hamstring Stretch",
          duration: 4,
          sets: 2,
          reps: 1,
          instructions: [
            "Stand up tall and place one foot on a low surface or in front of you.",
            "Lean forward at your hips, keeping your back straight, until you feel a stretch in your hamstring.",
            "Hold the stretch, then switch legs for 30 seconds.",
          ],
        },
        {
          id: "22",
          title: "Chest Stretch",
          duration: 4,
          sets: 2,
          reps: 1,
          instructions: [
            "Stand in a doorway and place your forearms on the doorframe.",
            "Step forward slightly and push your chest through the doorway until you feel a stretch in your chest muscles.",
            "Hold the stretch for 30 seconds.",
          ],
        },
        {
          id: "23",
          title: "Hip Flexor Stretch",
          duration: 4,
          sets: 2,
          reps: 1,
          instructions: [
            "Kneel on one knee with the other foot in front, forming a 90-degree angle.",
            "Push your hips forward while keeping your back straight until you feel a stretch in your hip flexor.",
            "Hold the stretch, then switch legs for 30 seconds each side.",
          ],
        },
      ],
    },
    {
      workoutId: "8",
      title: "Core and Balance",
      image: "core-and-balance.jpg",
      macros: {
        duration: 14,
        calories: 90,
        numExercises: 3,
      },
      exercises: [
        {
          id: "24",
          title: "Single-Leg Deadlift",
          duration: 5,
          sets: 3,
          reps: 12,
          instructions: [
            "Stand on one leg with the other leg slightly lifted behind you.",
            "Hinge at the hips and lower your torso toward the floor, extending the lifted leg behind you.",
            "Return to standing while keeping your core tight and balance steady.",
          ],
        },
        {
          id: "25",
          title: "Plank to Shoulder Tap",
          duration: 4,
          sets: 3,
          reps: 20,
          instructions: [
            "Start in a plank position with your hands directly under your shoulders.",
            "Lift one hand and tap the opposite shoulder, trying not to let your hips shift.",
            "Alternate hands for the duration of the exercise.",
          ],
        },
        {
          id: "26",
          title: "Side Plank",
          duration: 5,
          sets: 2,
          reps: 1,
          instructions: [
            "Lie on your side with your legs straight and your forearm directly under your shoulder.",
            "Lift your hips off the ground to form a straight line from head to heels.",
            "Hold this position for 30 seconds without letting your hips sag, then switch sides.",
          ],
        },
      ],
    },
    {
      workoutId: "9",
      title: "Active Recovery",
      image: "active-recovery.jpg",
      macros: {
        duration: 10,
        calories: 60,
        numExercises: 2,
      },
      exercises: [
        {
          id: "27",
          title: "Child’s Pose",
          duration: 5,
          sets: 2,
          reps: 1,
          instructions: [
            "Start on your hands and knees, then sit back onto your heels while reaching your arms forward.",
            "Relax your body into the stretch and hold for 60 seconds.",
          ],
        },
        {
          id: "28",
          title: "Seated Forward Fold",
          duration: 5,
          sets: 2,
          reps: 1,
          instructions: [
            "Sit on the floor with legs extended in front of you.",
            "Hinge at your hips and reach toward your feet, keeping your spine long.",
            "Hold the stretch for 60 seconds while breathing deeply.",
          ],
        },
      ],
    },
    {
      workoutId: "10",
      title: "Leg and Glute Shaper",
      image: "leg-and-glute-shaper.jpg",
      macros: {
        duration: 18,
        calories: 140,
        numExercises: 4,
      },
      exercises: [
        {
          id: "29",
          title: "Squat to Calf Raise",
          duration: 5,
          sets: 3,
          reps: 15,
          instructions: [
            "Stand with feet shoulder-width apart.",
            "Lower into a squat, then rise onto your toes as you stand back up.",
            "Hold the calf raise for 1-2 seconds, then repeat.",
          ],
        },
        {
          id: "30",
          title: "Step-Ups",
          duration: 5,
          sets: 3,
          reps: 12,
          instructions: [
            "Find a sturdy chair or step.",
            "Step one foot onto the chair and drive through your heel to lift yourself up.",
            "Lower back down with control and switch legs.",
          ],
        },
        {
          id: "31",
          title: "Glute Kickbacks",
          duration: 4,
          sets: 3,
          reps: 15,
          instructions: [
            "Start on all fours with hands under shoulders and knees under hips.",
            "Extend one leg back and upward, squeezing your glutes at the top.",
            "Return to the starting position and switch legs.",
          ],
        },
        {
          id: "32",
          title: "Wall Sit",
          duration: 4,
          sets: 2,
          reps: 1,
          instructions: [
            "Stand with your back against a wall and slide down until your knees form a 90-degree angle.",
            "Hold this position for 30 seconds, keeping your core tight and back flat against the wall.",
          ],
        },
      ],
    },
    {
      workoutId: "11",
      title: "Cardio Core Combo",
      image: "cardio-core-combo.jpg",
      macros: {
        duration: 16,
        calories: 120,
        numExercises: 3,
      },
      exercises: [
        {
          id: "33",
          title: "Burpees",
          duration: 6,
          sets: 3,
          reps: 10,
          instructions: [
            "Start standing, then squat down and place your hands on the floor.",
            "Jump your feet back into a plank position.",
            "Quickly jump your feet back toward your hands and jump up explosively.",
          ],
        },
        {
          id: "34",
          title: "Bicycle Crunches",
          duration: 5,
          sets: 3,
          reps: 20,
          instructions: [
            "Lie on your back with your hands behind your head.",
            "Lift your shoulders off the mat and bring one knee toward your chest while twisting your torso to touch the opposite elbow to the knee.",
            "Switch sides in a pedaling motion.",
          ],
        },
        {
          id: "35",
          title: "Plank",
          duration: 5,
          sets: 3,
          reps: 1,
          instructions: [
            "Start in a push-up position but with your forearms on the ground.",
            "Keep your body in a straight line from head to heels.",
            "Hold this position for 30 seconds without letting your hips sag.",
          ],
        },
      ],
    },
  ],
  male: [
    {
      workoutId: "0",
      title: "Lower Body Toner",
      macros: {
        duration: 15,
        calories: 120,
        numExercises: 3,
      },
      exercises: [
        {
          id: "0",
          title: "Bodyweight Squats",
          duration: 5,
          sets: 3,
          reps: 15,
          instructions: [
            "Stand with feet shoulder-width apart.",
            "Lower your body as if sitting back into a chair, keeping your chest up.",
            "Push through your heels to return to the standing position.",
          ],
        },
        {
          id: "1",
          title: "Glute Bridges",
          duration: 5,
          sets: 3,
          reps: 20,
          instructions: [
            "Lie on your back with knees bent and feet flat on the floor.",
            "Push through your heels to lift your hips off the ground until your body forms a straight line.",
            "Lower your hips back down to the starting position.",
          ],
        },
        {
          id: "2",
          title: "Lunges",
          duration: 5,
          sets: 3,
          reps: 12,
          instructions: [
            "Stand tall and take a step forward with one leg.",
            "Lower your body until both knees are bent at a 90-degree angle.",
            "Push back to the starting position and repeat with the other leg.",
          ],
        },
      ],
    },
    {
      workoutId: "1",
      title: "Upper Body Power",
      macros: {
        duration: 15,
        calories: 110,
        numExercises: 3,
      },
      exercises: [
        {
          id: "3",
          title: "Push-Ups",
          duration: 5,
          sets: 3,
          reps: 15,
          instructions: [
            "With your legs extended back, place the hands below the shoulders, slightly wider than shoulder-width apart.",
            "Start bending your elbows and lower your chest until it's just above the floor.",
            "Push back to the starting position.",
          ],
        },
        {
          id: "4",
          title: "Tricep Dips",
          duration: 5,
          sets: 3,
          reps: 12,
          instructions: [
            "Sit on the edge of a chair, hands gripping the edge next to your hips.",
            "Move your hips off the chair and lower your body until your elbows form a 90-degree angle.",
            "Push back up to straighten your arms.",
          ],
        },
        {
          id: "5",
          title: "Bicep Curls with Resistance Bands",
          duration: 5,
          sets: 3,
          reps: 15,
          instructions: [
            "Stand on the resistance band with feet shoulder-width apart.",
            "Hold the handles with palms facing up.",
            "Curl the handles toward your shoulders, keeping your elbows close to your sides.",
          ],
        },
      ],
    },
    {
      workoutId: "2",
      title: "Core Strength",
      macros: {
        duration: 12,
        calories: 90,
        numExercises: 3,
      },
      exercises: [
        {
          id: "6",
          title: "Plank",
          duration: 3,
          sets: 3,
          reps: 30,
          instructions: [
            "Start in a push-up position but with your forearms on the ground.",
            "Keep your body in a straight line from head to heels.",
            "Hold this position for 30 seconds.",
          ],
        },
        {
          id: "7",
          title: "Russian Twists",
          duration: 4,
          sets: 3,
          reps: 20,
          instructions: [
            "Sit on the floor with your knees bent and feet lifted.",
            "Twist your torso to the right, bringing your hands or a weight to the side.",
            "Repeat on the left side.",
          ],
        },
        {
          id: "8",
          title: "Leg Raises",
          duration: 5,
          sets: 3,
          reps: 15,
          instructions: [
            "Lie on your back with legs extended.",
            "Raise your legs to a 90-degree angle, keeping them straight.",
            "Lower your legs slowly without letting them touch the floor.",
          ],
        },
      ],
    },
    {
      workoutId: "3",
      title: "Full Body Circuit",
      macros: {
        duration: 18,
        calories: 150,
        numExercises: 4,
      },
      exercises: [
        {
          id: "9",
          title: "Jumping Jacks",
          duration: 3,
          sets: 2,
          reps: 25,
          instructions: [
            "Stand with feet together and arms at your sides.",
            "Jump your feet out to the sides while bringing your arms overhead.",
            "Jump back to the starting position.",
          ],
        },
        {
          id: "10",
          title: "Mountain Climbers",
          duration: 3,
          sets: 3,
          reps: 20,
          instructions: [
            "Start in a high plank position.",
            "Quickly drive one knee toward your chest, then switch legs.",
            "Continue alternating legs as fast as you can.",
          ],
        },
        {
          id: "11",
          title: "Bodyweight Squats",
          duration: 5,
          sets: 3,
          reps: 15,
          instructions: [
            "Stand with feet shoulder-width apart.",
            "Lower your body as if sitting back into a chair, keeping your chest up.",
            "Push through your heels to return to the standing position.",
          ],
        },
        {
          id: "12",
          title: "Push-Ups",
          duration: 5,
          sets: 3,
          reps: 10,
          instructions: [
            "With your legs extended back, place the hands below the shoulders, slightly wider than shoulder-width apart.",
            "Start bending your elbows and lower your chest until it's just above the floor.",
            "Push back to the starting position.",
          ],
        },
      ],
    },
    {
      workoutId: "4",
      title: "Morning Mobility",
      macros: {
        duration: 10,
        calories: 60,
        numExercises: 2,
      },
      exercises: [
        {
          id: "13",
          title: "Cat-Cow Stretch",
          duration: 5,
          sets: 3,
          reps: 10,
          instructions: [
            "Start on all fours with hands under shoulders and knees under hips.",
            "Arch your back (Cow) and lift your head and tailbone upward.",
            "Round your back (Cat) while tucking your chin and pelvis.",
          ],
        },
        {
          id: "14",
          title: "Downward Dog to Cobra",
          duration: 5,
          sets: 3,
          reps: 8,
          instructions: [
            "Start in a downward dog position with your hips lifted and heels pressed toward the floor.",
            "Shift forward into a plank position and lower into Cobra, arching your back and lifting your chest.",
            "Push back to downward dog.",
          ],
        },
      ],
    },
    {
      workoutId: "5",
      title: "Cardio Blaster",
      macros: {
        duration: 15,
        calories: 130,
        numExercises: 3,
      },
      exercises: [
        {
          id: "15",
          title: "High Knees",
          duration: 5,
          sets: 3,
          reps: 30,
          instructions: [
            "Stand in place and run while lifting your knees as high as possible.",
            "Keep your core tight and move as quickly as possible.",
          ],
        },
        {
          id: "16",
          title: "Jumping Jacks",
          duration: 5,
          sets: 3,
          reps: 25,
          instructions: [
            "Stand with feet together and arms at your sides.",
            "Jump your feet out to the sides while bringing your arms overhead.",
            "Jump back to the starting position.",
          ],
        },
        {
          id: "17",
          title: "Butt Kicks",
          duration: 5,
          sets: 3,
          reps: 30,
          instructions: [
            "Stand in place and kick your heels toward your glutes while jogging in place.",
            "Move quickly to keep your heart rate up.",
          ],
        },
      ],
    },
    {
      workoutId: "6",
      title: "Back and Shoulders Focus",
      macros: {
        duration: 15,
        calories: 110,
        numExercises: 3,
      },
      exercises: [
        {
          id: "18",
          title: "Resistance Band Rows",
          duration: 5,
          sets: 3,
          reps: 15,
          instructions: [
            "Sit on the floor with your legs extended and the resistance band looped around your feet.",
            "Hold the ends of the band with both hands and pull them toward your waist, squeezing your shoulder blades together.",
            "Slowly return to the starting position.",
          ],
        },
        {
          id: "19",
          title: "Lateral Raises with Dumbbells or Bands",
          duration: 5,
          sets: 3,
          reps: 12,
          instructions: [
            "Stand with feet shoulder-width apart, holding dumbbells or bands at your sides.",
            "Raise your arms out to the sides until they are parallel to the ground.",
            "Lower the weights back down with control.",
          ],
        },
        {
          id: "20",
          title: "Superman",
          duration: 5,
          sets: 3,
          reps: 15,
          instructions: [
            "Lie face down with arms extended overhead.",
            "Lift your arms, chest, and legs off the ground simultaneously, squeezing your glutes and lower back.",
            "Hold for 1-2 seconds before lowering back down.",
          ],
        },
      ],
    },
    {
      workoutId: "7",
      title: "Core Stabilizer",
      macros: {
        duration: 12,
        calories: 90,
        numExercises: 3,
      },
      exercises: [
        {
          id: "21",
          title: "Side Plank",
          duration: 4,
          sets: 3,
          reps: 30,
          instructions: [
            "Lie on your side with your legs extended and feet stacked.",
            "Place your elbow under your shoulder and lift your hips to form a straight line.",
            "Hold this position for 30 seconds, then switch sides.",
          ],
        },
        {
          id: "22",
          title: "Bird-Dog",
          duration: 4,
          sets: 3,
          reps: 15,
          instructions: [
            "Start on all fours with your hands under your shoulders and knees under your hips.",
            "Extend your right arm forward and left leg back, keeping your body balanced.",
            "Return to the starting position and switch sides.",
          ],
        },
        {
          id: "23",
          title: "Bicycle Crunches",
          duration: 4,
          sets: 3,
          reps: 20,
          instructions: [
            "Lie on your back with knees bent and hands behind your head.",
            "Lift your shoulders off the floor and twist your torso to bring your right elbow toward your left knee.",
            "Alternate sides in a pedaling motion.",
          ],
        },
      ],
    },
    {
      workoutId: "8",
      title: "Flexibility Boost",
      macros: {
        duration: 10,
        calories: 60,
        numExercises: 2,
      },
      exercises: [
        {
          id: "24",
          title: "Forward Fold Stretch",
          duration: 5,
          sets: 3,
          reps: 10,
          instructions: [
            "Stand with feet hip-width apart and hinge at your hips to fold forward.",
            "Let your arms hang toward the floor and relax your head and neck.",
            "Hold the stretch for 30 seconds before slowly rolling up.",
          ],
        },
        {
          id: "25",
          title: "Hip Flexor Stretch",
          duration: 5,
          sets: 3,
          reps: 10,
          instructions: [
            "Kneel on your right knee with your left foot in front, forming a 90-degree angle.",
            "Shift your weight forward to stretch the hip flexor of your right leg.",
            "Hold for 30 seconds, then switch sides.",
          ],
        },
      ],
    },
    {
      workoutId: "9",
      title: "Low Impact Cardio",
      macros: {
        duration: 15,
        calories: 110,
        numExercises: 3,
      },
      exercises: [
        {
          id: "26",
          title: "Step-Ups",
          duration: 5,
          sets: 3,
          reps: 20,
          instructions: [
            "Use a sturdy chair or step for this exercise.",
            "Step up with your right foot, followed by your left, then step down.",
            "Alternate legs for each repetition.",
          ],
        },
        {
          id: "27",
          title: "Standing March",
          duration: 5,
          sets: 3,
          reps: 30,
          instructions: [
            "Stand in place and lift your knees up to hip height, alternating legs.",
            "Keep a steady pace and engage your core for stability.",
          ],
        },
        {
          id: "28",
          title: "Toe Taps",
          duration: 5,
          sets: 3,
          reps: 25,
          instructions: [
            "Stand in front of a step or sturdy object.",
            "Tap your toes on the edge of the step one foot at a time, alternating quickly.",
            "Keep your core engaged and maintain a light jog-like motion.",
          ],
        },
      ],
    },
    {
      workoutId: "10",
      title: "Leg Day Essentials",
      macros: {
        duration: 15,
        calories: 120,
        numExercises: 3,
      },
      exercises: [
        {
          id: "29",
          title: "Wall Sit",
          duration: 5,
          sets: 3,
          reps: 60,
          instructions: [
            "Stand with your back against a wall and lower yourself into a sitting position.",
            "Hold the position with your knees bent at a 90-degree angle for 60 seconds.",
            "Push through your heels to stand up and rest.",
          ],
        },
        {
          id: "30",
          title: "Calf Raises",
          duration: 5,
          sets: 3,
          reps: 20,
          instructions: [
            "Stand with your feet shoulder-width apart, and slowly raise your heels off the ground.",
            "Lower your heels back down with control and repeat.",
          ],
        },
        {
          id: "31",
          title: "Sumo Squats",
          duration: 5,
          sets: 3,
          reps: 15,
          instructions: [
            "Stand with your feet wider than shoulder-width apart and toes pointed outward.",
            "Lower your body into a squat, keeping your knees aligned with your toes.",
            "Push through your heels to return to the starting position.",
          ],
        },
      ],
    },
    {
      workoutId: "11",
      title: "Recovery Stretch Routine",
      macros: {
        duration: 10,
        calories: 50,
        numExercises: 3,
      },
      exercises: [
        {
          id: "32",
          title: "Child’s Pose",
          duration: 3,
          sets: 3,
          reps: 5,
          instructions: [
            "Start on all fours and sit your hips back toward your heels.",
            "Extend your arms in front of you and lower your forehead to the floor.",
            "Hold the stretch for 30 seconds.",
          ],
        },
        {
          id: "33",
          title: "Seated Hamstring Stretch",
          duration: 3,
          sets: 3,
          reps: 5,
          instructions: [
            "Sit on the floor with one leg extended and the other leg bent with your foot against your inner thigh.",
            "Reach toward the toes of your extended leg, keeping your back straight.",
            "Hold for 30 seconds, then switch sides.",
          ],
        },
        {
          id: "34",
          title: "Chest Opener Stretch",
          duration: 4,
          sets: 3,
          reps: 5,
          instructions: [
            "Stand tall and interlace your fingers behind your back.",
            "Lift your chest up and pull your hands down toward the floor to stretch your chest.",
            "Hold for 30 seconds, then release.",
          ],
        },
      ],
    },
  ],
};
