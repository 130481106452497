import styled from "styled-components";
import { useParams, useNavigate } from "react-router-dom";
import { BackSvg } from "../../assets/svg/BackSvg";
import { HeartFullSvg } from "../../assets/svg/HeartFullSvg";
import { HeartSvg } from "../../assets/svg/HeartSvg";
import { useWorkoutPlan } from "../../state/workoutPlan";
import { WorkoutExerciseItem } from "./WorkoutExerciseItem";

const BackButton = styled.button`
  top: 1rem;
  left: 1rem;
  z-index: 10;
  width: 2rem;
  height: 2rem;
  position: fixed;
  border-radius: 50%;
  background: white;
  display: flex;
  border: none;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const FavButton = styled.button`
  top: 1rem;
  right: 1rem;
  z-index: 10;
  width: 2rem;
  height: 2rem;
  padding-top: 2px;
  position: fixed;
  border-radius: 50%;
  background: white;
  display: flex;
  border: none;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const WorkoutImage = styled.img`
  width: 100%;
  max-height: 30vh;
  object-fit: cover;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  padding: 0 1rem;
  padding-top: 1rem;
  padding-bottom: 2rem;
  flex-direction: column;
`;

const Title = styled.h1`
  margin: 0;
  font-size: 20px;
  line-height: 2rem;
  font-weight: 700;
`;

const Details = styled.p`
  font-size: 14px;
  color: #666;
  margin: 0;
  margin-bottom: 16px;
`;

const Disclaimer = styled.p`
  font-size: 14px;
  color: #666;
  margin: 0;
`;

const SectionTitle = styled.h2`
  font-size: 18px;
  margin-bottom: 0.5rem;
`;

const ExercisesList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0;
  margin-bottom: 16px;
`;

const Card = styled.div`
  display: flex;
  padding: 1rem;
  margin-bottom: 2rem;
  border-radius: 5px;
  background: #ffffff;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
`;

export const WorkoutView = () => {
  const navigate = useNavigate();
  const { workoutId } = useParams();
  const { workouts, loved, toggleLove } = useWorkoutPlan();
  const workout = workouts[workoutId as any];

  const isLikedWorkout = loved[workout.workoutId];

  if (!workout) {
    // Handle the case where the workout is not found
    return <div>Workout not found.</div>;
  }

  return (
    <>
      <BackButton onClick={() => navigate(-1)}>
        <BackSvg />
      </BackButton>
      <FavButton onClick={() => toggleLove(workout.workoutId)}>
        {isLikedWorkout ? <HeartFullSvg /> : <HeartSvg />}
      </FavButton>
      <WorkoutImage
        src={`/images/workouts/${workout.image}`}
        alt={workout.title}
      />
      <Content>
        <Title>{workout.title}</Title>
        <Details>
          {workout.macros.duration} min · 🔥 {workout.macros.calories} kcal ·{" "}
          {workout.macros.numExercises} exercises
        </Details>
        <SectionTitle>Exercises</SectionTitle>
        <ExercisesList>
          {workout.exercises?.map((exercise, index) => (
            <WorkoutExerciseItem
              key={`workout-exercise-${index}`}
              exercise={exercise}
              onExerciseTriggered={() =>
                navigate(
                  `/workouts/${workout.workoutId}/exercise/${exercise.id}`
                )
              }
            />
          ))}
        </ExercisesList>
        <Card>
          <Disclaimer>
            * Your workout might look different than the picture since workouts
            are adjusted to your specific goals
          </Disclaimer>
        </Card>
      </Content>
    </>
  );
};
